import React from "react";
import { Stack } from "@mui/material";

import BunfanBanner from 'assets/images/bunfan_banner.png'

const BannerImage = () => {
	const bannerStyle = {
		width: "100%",
		margin: -6,
		padding: 0,
	};
	return (
		<img style={bannerStyle} src={BunfanBanner} />
	)
}

const Banner = () => {


	return (
		<BannerImage />
	);
};

export default Banner;
