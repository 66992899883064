import React from 'react';
import {
    Stack,
    Container,
    Button,
    Link,
} from "@mui/material";

import AboutImage from 'assets/images/site_thumb.png'

const Image = () => {
    return (
        <Container maxWidth="sm">
            <img src={AboutImage} />
        </Container>
    )
}

const Information = () => {
    return (
        <Container maxWidth="md" >
            <h1 style={{ fontFamily: 'Bebas Neue, sans-serif', letterSpacing: 3, textAlign: 'center' }}>ABOUT US 🚀</h1>
            <p>
                We are a small video game development team specializing in the creation
                of adult-themed video games and media, our team harnesses the versatility
                and robust features of the Godot Engine to deliver captivating and sexy experiences.
                With a keen focus on innovation and quality, we continuously push the boundaries of
                creativity in the realm of adult games.</p>
            < br />

        </Container>
    )
}

const About = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={-1}
            className='black'
            maxHeight={500}
        >
            <Information />
            {/* <Image /> */}
        </Stack >

    )
}

export default About;