import React from 'react';
import {
    Stack,
    Container,
    Button,
    Link
} from "@mui/material";

import DawnaImage from 'assets/images/characters/dawna_icon.png'
import DiscordLogo from 'assets/logos/discord.svg'

const NewsItems = () => {
    return (
        <Container maxWidth="sm">
            <img src={DawnaImage} style={{ position: 'relative', top: '21px' }} />
        </Container>
    )
}

const Info = () => {
    return (
        <Container maxWidth="sm">
            <h1 style={{ fontFamily: 'Bebas Neue, sans-serif', letterSpacing: 3 }}>NEWS 📰</h1>
            <p>No new announcements to share at the moment, exciting updates are on the horizon!
                Keep an eye out for what's to come by staying tuned to our channels!
                <br /><br />
                You can also join our discord for more frequent information in our announcement channel!</p>
            <br />
            <Link target="_blank" rel="noopener" href="https://discord.gg/HHFAFUCv8e">
                <Button
                    variant="contained"
                    style={{ letterSpacing: 1, fontSize: 24, fontFamily: 'Bebas Neue, sans-serif', backgroundColor: '#5865F2', color: '#fff' }}
                    startIcon={<img src={DiscordLogo} style={{ height: '26px', marginRight: 5, color: '#fff' }} />}
                >
                    Join Our Discord
                </Button>
            </Link>
        </Container>
    )
}


const News = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={-1}
            className="gray"
        >


            <Info />
            <NewsItems />

        </Stack>

    )
}

export default News;