import React from 'react';
import {
    Stack,
    Link,
    Container,
    Button,
    Avatar,
} from "@mui/material";

import BBTrailerVideo from 'assets/videos/bb_trailer.webm'
import ItchioLogo from 'assets/logos/itchio.svg'
import SteamLogo from 'assets/logos/steam_icon.png'

const Trailer = () => {
    const videoStyle = {
        border: '4px solid #fff', // Adjust border properties as needed
        borderRadius: '4px', // Adjust border properties as needed
        boxShadow: '0 0 20px 5px #ffffff22', // Adjust glow color and size as needed
    };

    return (
        <div>
            <video style={videoStyle} width="90%" autoPlay muted loop>
                <source src={BBTrailerVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}


const BeatBanger = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={-1}
            className="black"
        >

            <Container maxWidth="sm">
                <Trailer />
            </Container>

            <Container maxWidth="sm">
                <h1 style={{ fontFamily: 'Bebas Neue, sans-serif', letterSpacing: 3 }}>Beat Banger 🎧</h1>
                <p>Beat Banger is a mature furry rhythm game In the early development phase.
                    Enter the lone hero in a rags-to-bitches story.
                    Work your way to the top of Beat Banger Studios,
                    a rhythmic pornography company in desperate need of some assistance.</p>
                < br />
                <Stack
                    direction="row"
                    justifyContent="left"
                    alignItems="left"
                    spacing={3}
                >
                    <Link target="_blank" rel="noopener" href="https://store.steampowered.com/app/1813430/Beat_Banger/">
                        <Button
                            style={{ fontSize: 24, fontFamily: 'Bebas Neue, sans-serif', backgroundColor: '#1b2838', color: '#c7d5e0' }}
                            variant="contained"
                            startIcon={<img src={SteamLogo} style={{ height: '30px', marginRight: 5 }} />}
                        >PLAY ON STEAM
                        </Button>
                    </Link>

                    <h3 style={{ letterSpacing: 3, marginTop: '12px', fontSize: 24, fontFamily: 'Bebas Neue, sans-serif' }}>OR</h3>

                    <Link target="_blank" rel="noopener" href="https://bunfan.itch.io/beat-banger">
                        <Button
                            style={{ fontSize: 24, fontFamily: 'Bebas Neue, sans-serif', backgroundColor: '#fa5c5c', color: '#fff' }}
                            variant="contained"
                            startIcon={<img src={ItchioLogo} style={{ height: '28px', marginRight: 5, color: '#fff' }} />}
                        >
                            PLAY ON Itch.io
                        </Button>
                    </Link>
                </Stack>
            </Container >

        </Stack >

    )
}

export default BeatBanger;