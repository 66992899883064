import React from 'react';
import StaffList from "lib/staff";
import GoogleFontLoader from 'react-google-font-loader';
import {
    Stack,
    Link,
    Container,
    Button,
} from "@mui/material";

const StaffIcons = () => {

    return (

        <Container maxWidth="sm">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                useFlexGap
                flexWrap="wrap"
                spacing={1}
            >
                {StaffList.map((member, i) => (
                    <Link className="staffIcon" key={i} target="_blank" rel="noopener" href={member.twitter}>
                        <img alt={member.name} src={member.avatarUrl} />
                    </Link>
                ))}
            </Stack>
        </Container>
    )
}

const Info = () => {
    return (
        <Container maxWidth="sm">
            <h1 style={{ fontFamily: 'Bebas Neue, sans-serif', letterSpacing: 3 }}>THE TEAM 🤝</h1>
            <p>Meet individuals who form the backbone of our team.
                Each member contributes their unique skills to bring our games to life. </p>
            < br />
            <Button
                variant="contained"
                disabled
                style={{ letterSpacing: 1, fontSize: 24, fontFamily: 'Bebas Neue, sans-serif' }}
            >
                More Info Coming Soon
            </Button>
        </Container>
    )
}

const Staff = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={-1}
            className="gray"
        >

            <Info />
            <StaffIcons />
        </Stack>

    )
}

export default Staff;