import KomdogIcon from "assets/images/staff/komdog.png";
import BlueKoboldIcon from "assets/images/staff/blue-kobold.png";
import UpesitoIcon from "assets/images/staff/upesito.png";
import ValIcon from "assets/images/staff/valentine.png";
import ImpIcon from "assets/images/staff/1mp.png";
import KogaIcon from "assets/images/staff/koga.png";
import MoistIcon from "assets/images/staff/moist.png"

const Staff = [
	{
		name: "KOMDOG",
		role: "Director",
		avatarUrl: KomdogIcon,
		twitter: "https://twitter.com/komnsfw",
	},
	{
		name: "BLUE",
		role: "Manager",
		avatarUrl: BlueKoboldIcon,
		twitter: "https://twitter.com/BlueAsAllHeck",
	},
	{
		name: "UPESITO",
		role: "Art Director",
		avatarUrl: UpesitoIcon,
		twitter: "https://twitter.com/UltraHyper",
	},
	{
		name: "VAL",
		role: "Artist",
		avatarUrl: ValIcon,
		twitter: "https://www.youtube.com/watch?v=dQw4w9WgXcQ",
	},
	{
		name: "1MP",
		role: "UI Artist / QA",
		avatarUrl: ImpIcon,
		twitter: "https://twitter.com/_1MP_",
	},
	{
		name: "KAPIOGA",
		role: "Developer",
		avatarUrl: KogaIcon,
		twitter: "https://twitter.com/WaferKapn",
	},
	{
		name: "MOIST.G",
		role: "Merchandise",
		avatarUrl: MoistIcon,
		twitter: "https://twitter.com/MoistGuava",
	},
];

export default Staff;
