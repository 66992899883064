import "../styles/styles.css";
import React from "react";

import Layout from "components/Layout";
// import Header from "components/Header";
import Seo from "components/seo";

import Banner from "components/Banner";
import About from "components/About";
import BeatBanger from "components/BeatBanger";
import Characters from "components/Characters";
import News from "components/News";
import Staff from "components/Staff";
import Links from "components/Links";
import Merch from "components/Merch";

import BunfanTextLogo from "assets/logos/bunfan-text-logo.jpg";

const Index = () => (
	// <AuthProvider>
	<Layout>
		{/* <Header /> */}
		<Seo
			title="Home"
			description="Bunfan.com. We do Games."
			image={BunfanTextLogo}
		/>
		<Banner />
		<About />
		<Staff />
		<BeatBanger />
		<News />
		<Merch />
		<Links />
	</Layout>

	// </AuthProvider>
);

export default Index;
// export default Index
