import React from 'react';
import {
    Stack,
    Link,
    Container,
    Button,
} from "@mui/material";

import StoreBannerImage from 'assets/images/store_banner.png'

const StoreBanner = () => {
    return (
        <Container maxWidth="sm">
            <img src={StoreBannerImage} style={{ width: "90%" }} />
        </Container>
    );
}

const Information = () => {
    return (
        <Container maxWidth="md">

            <h1 style={{ fontFamily: 'Bebas Neue, sans-serif', letterSpacing: 3 }}>
                MERCH STORE OPEN 🛍️
            </h1>

            <p>

                <b>Our merch store is now open for business! We've got some awesome Standees
                    and an official Beat Banger shirt available for purchase right now.</b>
                <br /><br />
                Stay tuned because we'll be adding even more fantastic items in the near future.
                Keep an eye out for updates and be the first to snag some swag before it's gone!







            </p>
            < br />

            <Stack
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={3}
            >
                <Link target="_blank" rel="noopener" href="https://store.bunfan.com/">
                    <Button variant="contained" style={{ backgroundColor: '#F8691E', letterSpacing: 1, fontSize: 24, fontFamily: 'Bebas Neue, sans-serif' }}>Check Out Our Merch</Button>
                </Link>
            </Stack>

        </Container>
    )
}

const Merch = () => {
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={-1}
            className="black"
        >

            {/* <StoreBanner /> */}
            <Information />

        </Stack>

    )
}

export default Merch;